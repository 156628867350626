


























































































































































































































































































import CharacterSelector from "@/components/training/CharacterSelector.vue";
import {
  CONTACT_HREF,
  PATREON_URL,
  REPO_GITHUB_RELEASES_API_URL,
  REPO_GITHUB_URL,
} from "@/constants";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";

interface ReleaseInfo {
  tag_name: string;
  html_url: string;
  created_at: string;
  published_at: string;
  name: string;
  body: string;
}

@Component({
  name: "home",
  components: { CharacterSelector },
})
export default class extends Vue {
  public contactHref = CONTACT_HREF;
  public githubUrl = REPO_GITHUB_URL;
  public latestReleaseInfo: ReleaseInfo | null = null;
  public patreonUrl = PATREON_URL;

  public async created() {
    const latestReleaseInfoRequest = await fetch(REPO_GITHUB_RELEASES_API_URL);
    this.latestReleaseInfo = await latestReleaseInfoRequest.json();
  }

  get releaseTime(): string {
    if (this.latestReleaseInfo === null) {
      return "unknown release time";
    } else {
      return moment(
        this.latestReleaseInfo.created_at,
        "YYYY-MM-DDTHH:mm:ssZ",
      ).fromNow();
    }
  }
}
